import React, { useEffect, useState } from "react";
import "./Hero.css";
import { Bounce, Fade, Flip, Roll, Zoom } from "react-reveal";
import lumona from "../../../../assets/images/lumona-hero.png";
import video from "../../../../assets/images/hero-vid.gif";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { Link } from "react-router-dom";
import lumonaResume from "../../../../assets/documents/lumonaResume.pdf";

export default function Hero() {
  const [moveBtn, setMoveBtn] = useState(false);
  const [isToDownload, setIsToDownload] = useState(false);

  useEffect(() => {
    // Trigger the button movement after a short delay (optional)
    const timer = setTimeout(() => {
      setMoveBtn(true);
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  const onhoverIn = (e) => {
    e.target.classList.add("hovered");
    setIsToDownload(true);
  };
  const onhoverOut = (e) => {
    e.target.classList.remove("hovered");
    setIsToDownload(false);
  };

  return (
    <div className="hero">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <Flip left>
            <div className="col-lg-4  hero-about d-flex flex-column justify-content-evenly">
              <Link to={"/about-me"} className="about-me">
                <div className="d-flex justify-content-center">
                  <div className="hero-about-image-div d-flex justify-content-center align-items-center">
                    <div className="hero-about-image-inner-div">
                      <img className="hero-about-img" src={lumona} />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="am">I'm,</h2>
                  <h2 className="first-name">Lumona</h2>
                  <h2 className="second-name">Mulengwa</h2>
                </div>
                <h2 className="mb-4">Fullstack Developer.</h2>
                <p>lumona@electrixitay.co</p>
              </Link>
            </div>
          </Flip>
          <div className="hero-content col-lg-8 d-flex flex-column justify-content-between">
            <Bounce>
              <h2>
                Software<span> Developer</span>
              </h2>
            </Bounce>
            <div className="row d-flex justify-content-between">
              <Zoom>
                <div className="col-lg-9 hero-portfolio">
                  <img src={video} alt="GIF background" className="video-bg" />
                  <div className="point-div">
                    <a
                      href="https://github.com/Lum-ona"
                      target={"_blank"}
                      rel="norefere"
                    >
                      <PanToolAltIcon className="point" />
                    </a>
                    <p>My Github</p>
                  </div>
                </div>
              </Zoom>

              <div className="col-lg-3 hero-stats">
                <Roll>
                  <div className="hero-yr">
                    <h3>6+</h3>
                    <p className="text-center">Years of Experience.</p>
                  </div>
                </Roll>
                <Flip>
                  <div className="hero-project">
                    <h3>20+</h3>
                    <p className="text-center">Projects Done</p>
                  </div>
                </Flip>
              </div>
            </div>
            <div className="row d-flex hero-download-services justify-content-between">
              <div className="col-lg-4 hero-download">
                <a
                  href={lumonaResume}
                  target="_blank"
                  rel={"norefere"}
                  className="hero-download-btn"
                  onMouseEnter={(e) => onhoverIn(e)}
                  onMouseLeave={(e) => onhoverOut(e)}
                >
                  {!isToDownload ? (
                    <p>Download My Resume</p>
                  ) : (
                    <Fade down>
                      <div className="hero-download-inner"></div>
                    </Fade>
                  )}
                </a>
              </div>
              <div className="col-lg-8 d-flex hero-services">
                <div className="w-50 hero-services-image"></div>
                <Link
                  to={"/services"}
                  className={`w-50 hero-services-btn ${moveBtn ? "move" : ""}`}
                >
                  Services I Offer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
